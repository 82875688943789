import { useMemo } from "react";

export const useApiUrl = () => {
  const isStaging =
    window.location.hostname === "localhost" ||
    window.location.hostname.includes("app");

  const host = isStaging
    ? process.env.REACT_APP_APIHOST
    : process.env.REACT_APP_APIHOST;

  const apiUrls = useMemo(
    () => ({
      getFeaturedProjects: `${host}${
        isStaging
          ? process.env.REACT_APP_API_GET_FEATURED_PROJECTS_STAGING
          : process.env.REACT_APP_API_GET_FEATURED_PROJECTS
      }`,
      getCurrentProjects: `${host}${
        isStaging
          ? process.env.REACT_APP_API_GET_CURRENT_PROJECTS_STAGING
          : process.env.REACT_APP_API_GET_CURRENT_PROJECTS
      }`,
      getProjects: `${host}${process.env.REACT_APP_API_GET_PROJECTS}`,
      getProjectById: (id) =>
        `${host}${process.env.REACT_APP_API_GET_PROJECT_BY_ID}${id}`,
      getServices: `${host}${process.env.REACT_APP_API_GET_SERVICES}`,
    }),
    [host, isStaging]
  );

  return apiUrls;
};
